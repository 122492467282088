.stock-transfer-stage-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.stock-transfer-stage-draft {
    background-color: #5486ea;
    color: #ffffff;
  }
  &.stock-transfer-stage-received {
    background-color: #ffa500ff;
    color: #000000;
  }
  &.stock-transfer-stage-in-transit {
    background-color: #29c76f;
    color: #ffffff;
  }
}
.stock-transfer-reference-column {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
