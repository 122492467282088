.edit-stock-transfer-dialog {
  .p-dialog-content {
    display: flex;
    #two-dialog-content {
      width: 100%;
      min-height: 750px;
    }
    .inventory-item-list-drag-and-drop-title{
      padding-bottom: 1rem;
      margin-top: -2rem;
    }
    #assignable_inventory_items_list_container, #assigned_inventory_items_list_container {
      height: 45%;
    }
  }
}
